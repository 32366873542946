
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHandshake} from '@fortawesome/free-solid-svg-icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button, Modal, Space } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;



function ServicesCarousel() {
    const { t } = useTranslation();
    const showRegister = () => {

        confirm({
            title: t('translation.registration'),
            content: <div>
                <p>{t('translation.registrationInfo')}</p>
                <ul>
                    <li>{t('translation.registrationInfo1')}</li>
                    <li>{t('translation.registrationInfo2')}</li>
                    <li>{t('translation.registrationInfo3')}</li>
                    <li>{t('translation.registrationInfo4')}</li>
                    <li>{t('translation.registrationInfo5')}</li>
                </ul>
                <p>{t('translation.registrationInfo6')}</p>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText: t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const showHHV = () => {
        confirm({
            title: t('translation.management'),
            content: <div>
                <b>{t('translation.acc')}</b>
                <p>{t('translation.accInfo')}</p>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText: t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const showConsulting = () => {
        confirm({
            title: t('translation.consultation'),
            content: <div>
                <ul>
                    <li>{t('translation.consultationInfo1')}</li>
                    <li>{t('translation.consultationInfo2')}</li>
                    <li>{t('translation.consultationInfo3')}</li>
                    <li>{t('translation.consultationInfo4')}</li>
                    <li>{t('translation.consultationInfo5')}</li>
                </ul>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText:t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const showHR = () => {
        confirm({
            title: t('translation.PA'),
            content: <div>
                <ul>
                    <li>{t('translation.PAInfo1')}</li>
                    <li>{t('translation.PAInfo2')}</li>
                    <li>{t('translation.PAInfo3')}</li>
                    <li>{t('translation.PAInfo4')}</li>
                    <li>{t('translation.PAInfo5')}</li>
                    <li>{t('translation.PAInfo6')}</li>
                </ul>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText: t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const showPlans = () => {
        confirm({
            title: t('translation.makingPlans'),
            content: <div>
                <p>{t('translation.makingPlansInfo1')}</p>
                <ul>
                    <li>{t('translation.makingPlansInfo2')}</li>
                    <li>{t('translation.makingPlansInfo3')}</li>
                    <li>{t('translation.makingPlansInfo4')}</li>
                    <li>{t('translation.makingPlansInfo5')}</li>
                    <li>{t('translation.makingPlansInfo6')}</li>
                </ul>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText: t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const showBanking = () => {
        confirm({
            title: t('translation.electronically'),
            content: <div>
                <p>{t('translation.electronicallyInfo')}</p>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText:t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const showJuridical = () => {
        confirm({
            title: t('translation.juridical'),
            content: <div>
                <ul>
                    <li>{t('translation.juridicalInfo1')}</li>
                    <li>{t('translation.juridicalInfo2')}</li>
                    <li>{t('translation.juridicalInfo3')}</li>
                    <li>{t('translation.juridicalInfo4')}</li>
                    <li>{t('translation.juridicalInfo5')}</li>
                </ul>
            </div>,
            cancelButtonProps: { style: { display: 'none' } },
            okText:t('translation.close'),
            centered: true,
            width: '50%',
            icon: <InfoCircleFilled />,
        });
    };
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <Carousel
            responsive={responsive}
            swipeable={false}
            ssr={true}
            infinite={true}
            autoPlay={true}
            keyBoardControl={true}
            removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
            className={'py-5'}
        >
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.registration')}</h4>
                <p className={'services-detail'}>{t('translation.registrationInfo')}
                </p>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button  className={'main-detail-btn service-btn'} onClick={showRegister}>{t('translation.more')}</Button>
                </div>
            </div>
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.management')}</h4>
                <p className={'services-detail'}>{t('translation.accData')} </p>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button  className={'main-detail-btn service-btn'} onClick={showHHV}>{t('translation.more')}</Button>
                </div>
            </div>
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.consultation')}</h4>
                <ul>
                    <li>{t('translation.consultationInfo1')}</li>
                    <li>{t('translation.consultationInfo2')}</li>
                </ul>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button className={'main-detail-btn service-btn'} onClick={showConsulting} type="dashed">
                        {t('translation.more')}
                    </Button>
                </div>
            </div>
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.PA')}</h4>
                <ul>
                    <li>{t('translation.PAInfo1')}</li>
                    <li>{t('translation.PAInfo2')}</li>
                    <li>{t('translation.PAInfo3')}</li>
                </ul>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button className={'main-detail-btn service-btn'} onClick={showHR} type="dashed">
                        {t('translation.more')}
                    </Button>
                </div>
            </div>
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.makingPlans')}</h4>
                <p className={'services-detail'}>{t('translation.makingPlansInfo1')}</p>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button className={'main-detail-btn service-btn'} onClick={showPlans}>{t('translation.more')}</Button>
                </div>
            </div>
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.electronically')}</h4>
                <p className={'services-detail'}>{t('translation.electronicallyData')}</p>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button className={'main-detail-btn service-btn'}  onClick={showBanking}>{t('translation.more')}</Button>
                </div>
            </div>
            <div className={'text-center services-block-div'}>
                <FontAwesomeIcon icon={faHandshake} className={'services-icon'}/>
                <h4 className="partner-name service-name text-capitalize">{t('translation.juridical')}</h4>
                <p className={'services-detail'}>{t('translation.juridicalData')}</p>
                <div className={'text-center w-100 mt-2 service-btn-footer'}>
                    <Button className={'main-detail-btn service-btn'}  onClick={showJuridical}>{t('translation.more')}</Button>
                </div>
            </div>
        </Carousel>
    );
}

export default ServicesCarousel;