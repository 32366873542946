
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTelegram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import './FooterMain.css'


export default function FooterMain() {
    const { t } = useTranslation();

    return (
        <div>
            <div className={'footer'}>
                <Container>
                    <Row>
                        <Col lg={4} md={6} sm={12} className='mb-4'>
                            <h5 className={'footer-title'}>{t('translation.aboutGabrielyans')}</h5>
                            <div className={'footer-small-line'}></div>
                            <p className={'footer-about-us'}>
                                {t('translation.footerAbout')}
                            </p>
                            <Row>
                                <Col className='mb-3'>
                                    <a href="https://www.facebook.com/gabrielyansaccounting/?locale=ru_RU" target="_blank">
                                    <div className={'footer-icon'}>
                                            <FontAwesomeIcon icon={faFacebookF}/>
                                    </div>
                                    </a>
                                </Col>
                                <Col className='mb-3'>
                                        <a href="https://t.me/+37477483777?fbclid=IwAR0PgUP_aMV3R0feQjLoozoBsnGhEZDegfJv-nxeSDB7aGxEFR22OEuDMKc" target="_blank">
                                            <div className={'footer-icon'}>
                                                <FontAwesomeIcon icon={faTelegram}/>
                                            </div>
                                        </a>
                                </Col>
                                <Col className='mb-3'>
                                        <a href="https://www.instagram.com/gabrielyans_accounting/" target="_blank">
                                            <div className={'footer-icon'}>
                                            <FontAwesomeIcon icon={faInstagram}/>
                                            </div>
                                        </a>
                                </Col>
                                <Col className='mb-3'>
                                    <a href="https://www.tiktok.com/@gabrielyansaccounting?fbclid=IwAR2_IV9744rQmUAXkm16jeA2_IJWYANMMwtNDO7rCl0IsB7SNN3s_MhsxtM" target="_blank">
                                    <div className={'footer-icon'}>
                                            <FontAwesomeIcon icon={faTiktok}/>
                                    </div>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                     <Col lg={4} md={6} sm={12} className={'mb-4 footer-contact'}>
                            <h5 className={'footer-title'}> {t('translation.contact')}</h5>
                            <div className={'footer-small-line'}></div>
                            <div className={'mt-3'}>
                                <FontAwesomeIcon icon={faMap}/>
                                <span> {t('translation.location')}</span>
                            </div>
                            <div className={'mt-3'}>
                                <FontAwesomeIcon icon={faMessage}/>
                                <span> info@gabrielyans.com </span>
                            </div>
                            <div className={'mt-3'}>
                                <FontAwesomeIcon icon={faPhone}/>
                                <span> +374 33777756 </span>
                            </div>
                        </Col>
                        <Col lg={4}  md={6} sm={12} className='mb-4'>
                            <h5 className={'footer-title'}> {t('translation.links')}</h5>
                            <div className={'footer-small-line'}></div>
                           <div className={'footer-third-column'}>
                               <p><a className="footer-links" href="#about"> {t('translation.history')}</a></p>
                               <p><a className="footer-links" href="#services"> {t('translation.services')}</a></p>
                               <p><a className="footer-links" href="#strategy"> {t('translation.strategy')}</a></p>
                               <p><a className="footer-links" href="#team"> {t('translation.ourTeam')}</a></p>
                               <p><a className="footer-links" href="#callback"> {t('translation.callback')}</a></p>
                           </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'small-footer'}>
                <p>© {new Date().getFullYear()} {t('translation.rights')}</p>
            </div>
        </div>
    );
}