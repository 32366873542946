import './App.css';
import {
  BrowserRouter as Router,
  Routes ,
  Route,
} from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={ <Layout/>}/>
        </Routes>
      </Router>
  );
}
