
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScaleBalanced, faInfo, faUsers, faPenToSquare, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './WhyChooseUs.css'

export default function WhyChooseUs() {
    const { t } = useTranslation();

    return (
        <div>
            <div className={'services-block py-5 mt-5 our-team-main'}>
                <div className={'partners-header-block text-center'}>
                    <div className={'title-design'}></div>
                    <h2 className={'block-title text-white'}>{t('translation.whyChooseUs')}</h2>
                    <div className="sub-title">{t('translation.weChoose')}</div>
                    <span className="bottom-title"></span>
                </div>
            </div>
            <Container className={'mt-5'}>
                <Row className={'why-choose-us'}>
                    <Col className='m-1'>
                        <FontAwesomeIcon icon={faPeopleArrows} className={'about-icons m-auto'}/>
                        <p>{t('translation.approach')}</p>
                    </Col>
                    <Col className='m-1'>
                        <FontAwesomeIcon icon={faPenToSquare} className={'about-icons  m-auto'}/>
                        <p>{t('translation.suggestSolutions')}</p>
                    </Col>
                    <Col className='m-1'>
                        <FontAwesomeIcon icon={faScaleBalanced} className={'about-icons  m-auto'}/>
                        <p>{t('translation.weFollow')}</p>
                    </Col>
                    <Col className='m-1'>
                        <FontAwesomeIcon icon={faInfo} className={'about-icons  m-auto'}/>
                        <p>{t('translation.confidentiality')}</p>
                    </Col>
                    <Col className='m-1'>
                        <FontAwesomeIcon icon={faUsers} className={'about-icons  m-auto'}/>
                        <p>{t('translation.trust')}</p>
                    </Col>
                </Row>
            </Container>
        </div>
            );
            }