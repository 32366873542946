
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import './Strategy.css'

export default function Strategy() {
    const { t } = useTranslation();

    return (
        <div>
            <div className={'services-block py-5 our-team-main'}>
                <div className={'partners-header-block text-center'}>
                    <div className={'title-design'}></div>
                    <h2 className={'block-title text-white'}>{t('translation.aims')}</h2>
                    <div className="sub-title">{t('translation.aimsInfo')}</div>
                    <span className="bottom-title"></span>
                </div>
            </div>
           <div className='mt-5'>
               <Container>
                   <Row>
                       <Col lg={4} md={6} className={'my-4'}>
                           <div className={'mission-bg'}>
                             <div className='str-detail'>
                                 <p className='m-0 mission-header'>
                                     {t('translation.mission')}
                                 </p>
                                 <p className='text mission-txt'>
                                     {t('translation.missionInfo')}
                                 </p>
                             </div>
                           </div>
                       </Col>
                       <Col lg={4} md={6} className={'my-4'}>
                           <div className={'strategy-bg'}>
                               <div className={'str-detail'}>
                                   <p className='m-0 strategy-header'>
                                       {t('translation.ourStrategy')}
                                   </p>
                                   <p className='text strategy-txt'>{t('translation.ourStrategyInfo')}
                                   </p>
                               </div>
                           </div>
                       </Col>
                       <Col lg={4} md={6} className={'my-4'}>
                           <div className={'vision-bg'}>
                               <div className={'str-detail'}>
                                   <p className='m-0 vision-header'>
                                       {t('translation.vision')}
                                   </p>
                                   <p className='text vision-txt'>{t('translation.visionInfo')}
                                   </p>
                               </div>
                           </div>
                       </Col>
                   </Row>
               </Container>
           </div>
        </div>
    );
}