import {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';
import './Counter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUsers, faPeopleGroup, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';



export default function Counter() {
    const [visitors, setVisitors] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        // setVisitors(ReactGA.pageview(window.location.pathname));
    }, []);
    return (
        <div className={'counter-block mt-5 py-5'}>
         <Container>
             <Row>

                 {/*<Col className={'d-flex'} lg={'3'} md={'6'}>*/}
                 {/*    <FontAwesomeIcon icon={faCheck} className={'about-icons border-0'}/>*/}
                 {/*    <span className={'counter'}><CountUp end={visitors} className={'counter-number'} /> <br/>*/}
                 {/*        <span className={'counter-detail'}>*/}
                 {/*           {t('translation.visitors')} */}
                 {/*        </span>*/}
                 {/*    </span>*/}
                 {/*</Col>*/}
                 <Col className={'d-flex'} lg={'4'} md={'6'}>
                     <FontAwesomeIcon icon={faUsers} className={'about-icons border-0'}/>
                     <span className={'counter'}><CountUp end={136} className={'counter-number'} /> <br/>
                         <span className={'counter-detail'}>
                            {t('translation.coworkers')}
                         </span>
                     </span>
                 </Col>
                 <Col className={'d-flex'} lg={'4'} md={'6'}>
                     <FontAwesomeIcon icon={faCalendarCheck} className={'about-icons border-0'}/>
                     <span className={'counter'}><CountUp end={5} className={'counter-number'} /> <br/>
                         <span className={'counter-detail'}>
                             {t('translation.experience')}
                         </span>
                     </span>
                 </Col>
                 <Col className={'d-flex'} lg={'4'} md={'6'}>
                     <FontAwesomeIcon icon={faPeopleGroup} className={'about-icons border-0'}/>
                     <span className={'counter'}><CountUp end={11} className={'counter-number'} /> <br/>
                         <span className={'counter-detail'}>
                              {t('translation.workers')}
                         </span>
                     </span>
                 </Col>
             </Row>
         </Container>
        </div>
    );
}
