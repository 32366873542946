import Container from 'react-bootstrap/Container';
import {
    Button, Modal,
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    Radio,
    Select, InputNumber,
} from 'antd';
import { useState } from 'react';
import './Header.css';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const { Option } = Select;


export default function Moto() {
    const { t } = useTranslation();
    const [transactions, setTransactions] = useState(false);
    const [cashBook, setCashBook] = useState(false);
    const handleCheckboxChange = (e) => {
        setTransactions(e.target.checked);
    };
    const handleCashBookChange = (e) => {
        setCashBook(e.target.checked);
    };
    const [form] = Form.useForm();
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="374">+374</Option>
            </Select>
        </Form.Item>
    );
    const onFinish = (values) => {

        const templateParams = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            message: values.message,
            type: values.type,
            version: values.version,
            count: values.count,
            import: values.import,
            export: values.export,
            costCount: values.costCount,
            turnover: values.turnover,
            licence: values.licence,
            cashBook: cashBook,
            transactions: transactions
        };
        emailjs.send('service_f992ahy', 'template_mwy966d', templateParams, 'a0YnHLwbwU4JvYEo_')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
        setModal2Open(false);
    }
        const [modal2Open, setModal2Open] = useState(false);
    return (
        <Container className={'mt-5 pt-5 header-moto'}>
            <div className="line moving-forward mt-3 text-center">
                <h6 className='lineUp'>
                    {t('translation.moto1')}</h6>
            </div>
            <div className="text-center">
                <h3 className="animate-character">
                    {t('translation.moto2')}</h3>
            </div>
            <div className="line moving-forward text-center">
                <h6 className='lineUp'>
                    {t('translation.moto3')}</h6>
            </div>
            <div className={'text-center animation-text'}>
                <Button  onClick={() => setModal2Open(true)} className={'main-detail-btn learn-more-btn animation-txt-detail lets-count-btn'}>
                    {t('translation.letsCount')}</Button>
            </div>
            <Modal
                title= {t('translation.letsCount')}
                centered
                open={modal2Open}
                onCancel={() => setModal2Open(false)}
                footer={[
                    <Button key="cancel" onClick={() => setModal2Open(false)}>
                        {t('translation.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" htmlType="submit" form="register">
                        {t('translation.send')}
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        name="name"
                        label={t('translation.name')}
                        rules={[
                            {
                                required: true,
                                message: t('translation.pleaseAddName'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label= {t('translation.mail')}
                        rules={[
                            {
                                type: 'email',
                                message: t('translation.pleaseAddMail'),
                            },
                            {
                                required: true,
                                message: t('translation.mandatory.'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label= {t('translation.phone')}
                        rules={[
                            {
                                required: true,
                                message: t('translation.pleaseAddPhone.'),
                            },
                        ]}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label={t('translation.select')}
                        rules={[
                            {
                                required: true,
                                message: t('translation.pleaseSelect.'),
                            },
                        ]}
                    >
                        <Select placeholder={t('translation.select')}>
                            <Option value="Միկրոձեռնարկություն">{t('translation.micro')}</Option>
                            <Option value="Շրջանառության հարկ">{t('translation.tax')}</Option>
                            <Option value="Ավելացված արժեքի հարկ (ԱԱՀ)">{t('translation.vat')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="version" label=""  rules={[
                        {
                            required: true,
                            message: t('translation.pleaseSelect'),
                        },
                    ]}>
                        <Radio.Group>
                            <Radio value="Անհատ ձեռնարկատեր (ԱՁ)">{t('translation.ps')}</Radio>
                            <Radio value="Սահմանափակ պատասխանատվությամբ ընկերություն (ՍՊԸ)">{t('translation.ltd')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="count" label={t('translation.workerCount')}  rules={[
                        {
                            required: true,
                            message: t('translation.pleaseNote'),
                        },
                    ]}>
                       <InputNumber/>
                    </Form.Item>
                    <Form.Item name="import" label={t('translation.import')}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="export" label={t('translation.export')}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="costCount" label={t('translation.payableAccounts')}  rules={[
                        {
                            required: true,
                            message: t('translation.pleaseAddCount'),
                        },
                    ]}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item name="turnover" label={t('translation.turnover')}  rules={[
                        {
                            required: true,
                            message: t('translation.pleaseAddTurnOver'),
                        },
                    ]}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        name="licence"
                        label={t('translation.licence')}
                        rules={[
                            {
                                required: true,
                                message: t('translation.pleaseSelect'),
                            },
                        ]}
                    >
                        <Select placeholder={t('translation.select')}>
                            <Option value="այո">{t('translation.yes')}</Option>
                            <Option value="ոչ">{t('translation.no')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label={t('translation.additionalInfo')}
                    >
                        <Input.TextArea showCount />
                    </Form.Item>
                    <Form.Item
                        name="cashBook"
                        valuePropName="cashBook"
                    >
                        <Checkbox onChange={handleCashBookChange}>
                            {t('translation.book')}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="transactions"
                        valuePropName="transactions"
                    >
                        <Checkbox onChange={handleCheckboxChange}>
                            {t('translation.bankingServices')}
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </Container>
    );
}
