import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../Images/gabrielyans-logo.png';
import './Header.css';
import { useTranslation } from 'react-i18next';
import {Select} from "antd";
import arm from "../../Images/arm.jpg";
import en from "../../Images/en.jpg";
import ru from "../../Images/rus.jpg";
import enTranslation from '../Translations/en/translation.json';
import armTranslation from '../Translations/arm/translation.json';
import ruTranslation from '../Translations/ru/translation.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


i18n
    .use(initReactI18next)
    .init(
        {
            resources: {
                armenian: {
                    translation: armTranslation,
                },
                english: {
                    translation: enTranslation,
                },
                russian: {
                    translation: ruTranslation,
                },
            },
            lng: 'armenian',
            fallbackLng: 'armenian',
            interpolation: {
                escapeValue: false,
            },
        },
        (err, t) => {
            if (err) {
                console.error('Error loading translations:', err);
            }
        }
    )
    .then((t) => {

    });

function NavSecond() {
    const [isNavbarFixed, setNavbarFixed] = useState(false);
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (value) => {
        console.log(value, 'value');
        i18n.changeLanguage(value);
    };

    const selectedLanguage = i18n.languages[0];
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            if (scrollPosition > 0 && !isNavbarFixed) {
                setNavbarFixed(true);
            } else if (scrollPosition === 0 && isNavbarFixed) {
                setNavbarFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavbarFixed]);

    const navbarClassName = isNavbarFixed
        ? 'navbar fixed second-nav'
        : 'navbar second-nav';

    return (
        <Navbar className={navbarClassName} expand="lg">
            <Container className={'pl-0'}>
                <Navbar.Brand href="#home" className={'p-0 logo-img'}>
                    <img src={logo} alt="logo" className={'w-100'} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className="ms-auto nav-titles">
                        <Select
                            defaultValue={selectedLanguage}
                            style={{ width: 100 }}
                            className={'select-lang d-lg-none d-md-block d-block'}
                            options={[
                                {
                                    value: 'armenian',
                                    label: (
                                        <img src={arm} className={'w-25'} alt="Armenian" />
                                    ),
                                },
                                {
                                    value: 'english',
                                    label: (
                                        <img src={en} className={'w-25'} alt="English" />
                                    ),
                                },
                                {
                                    value: 'russian',
                                    label: (
                                        <img src={ru} className={'w-25'} alt="Russian" />
                                    ),
                                },
                            ]}
                            onChange={handleLanguageChange}
                        />
                        <Nav.Link href="#home">
                            {t('translation.main')}</Nav.Link>
                        <Nav.Link href="#about">
                            {t('translation.aboutCompany')}</Nav.Link>
                        <Nav.Link href="#services">
                            {t('translation.services')}</Nav.Link>
                        <Nav.Link href="#strategy">
                            {t('translation.strategy')}</Nav.Link>
                        <Nav.Link href="#team">
                            {t('translation.we')}</Nav.Link>
                        <Nav.Link href="#callback">
                            {t('translation.contact')}</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavSecond;
