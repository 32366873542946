
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClock, faPhone, faMailBulk} from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Input,  InputNumber, message } from 'antd';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import './Callback.css'

export default function Callback() {
    const { t } = useTranslation();

    const onFinish = (values) => {
        const templateParams = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            message: values.message,
        };

        emailjs
            .send(
                'service_f992ahy',
                'template_sx5pnnx',
                templateParams,
                'a0YnHLwbwU4JvYEo_'
            )
            .then(
                () => {
                    message.success(t('translation.thankYou'));
                },
                (error) => {
                    message.error(t('translation.tryAgain'));
                    console.error('EmailJS Error:', error);
                }
            );
    };
    return (
        <div>
            <div className={'callback-main my-5'}>
                <Container className={'services-block py-5 ask-for-callback'}>
                    <div className={'partners-header-block text-center'}>
                        <div className={'title-design'}></div>
                        <h2 className={'block-title'}>{t('translation.askCallback')}</h2>
                        <div className="sub-title text-white">{t('translation.readyToHelp')}</div>
                        <span className="bottom-title"></span>
                    </div>
                </Container>
            </div>
           <div>
               <Form
                   name="contact-form"
                   onFinish={onFinish}
                   style={{
                       maxWidth: 800,
                       margin: 'auto',
                       marginBottom:'2rem'
                   }}
                   className='call-back-bg'
               >
                   <Form.Item
                       name='name'
                       rules={[
                           {
                               required: true,
                               message: t('translation.mandatoryToFill'),
                           },
                       ]}
                   >
                       <Input className={'callback-input'} placeholder={t('translation.yourName')} />
                   </Form.Item>
                   <Form.Item
                       name={t('translation.mail')}
                       rules={[
                           {
                               type: 'email',
                               message: t('translation.pleaseAddMail'),
                           },
                           {
                               required: true,
                               message: t('translation.mandatory'),
                           },
                       ]}
                   >
                       <Input className={'callback-input'} placeholder={ t('translation.yourMail')} />
                   </Form.Item>
                   <Form.Item name='phone'>
                       <InputNumber  className={'callback-input'} placeholder={ t('translation.yourPhone')} />
                   </Form.Item>
                   <Form.Item className={'mt-5'} name='message'>
                       <Input.TextArea className={'callback-input'} placeholder={t('translation.whatToDiscuss')} />
                   </Form.Item>
                   <Form.Item className={'text-center'}>
                       <Button className={'submit-for-callback'} type="primary" htmlType="submit">
                           {t('translation.send')}
                       </Button>
                   </Form.Item>
                   <Container className={'callback-detail mb-3'}>
                       <Row className={'mt-3'}>
                           <Col lg={6}>
                               <FontAwesomeIcon icon={faClock} className={'callback-icon'}/>
                               <span className={'callback-info-detail'}> {t('translation.monToFri')} </span>
                           </Col>
                           <Col lg={6}>
                               <FontAwesomeIcon icon={faClock} className={'callback-icon'}/>
                               <span className={'callback-info-detail'}> {t('translation.sat')} </span>
                           </Col>
                       </Row>
                       <Row className={'mt-3'}>
                           <Col lg={6}>
                               <FontAwesomeIcon icon={faPhone} className={'callback-icon'}/>
                               <span className={'callback-info-detail'}>+374 33777756 </span>
                           </Col>
                           <Col lg={6}>
                               <FontAwesomeIcon icon={faMailBulk} className={'callback-icon'}/>
                               <span className={'callback-info-detail'}>info@gabrielyans.com</span>
                           </Col>
                       </Row>
                   </Container>
               </Form
               >
           </div>
        </div>
    );
}