import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../Header/Navbar";
import NavbarSecond from "../Header/NavbarSecond";
import Moto from "../Header/HeaderMoto";
import About from "../About/About";
import Counter from "../About/Counter";
import Services from "../Services/Services";
import Team from "../Team/Team";
import Callback from "../Callback/Callback";
import FooterMain from "../FooterMain/FooterMain";
import Strategy from "../Strategy/Strategy";
import WhyChooseUs from "../WhyChoosUs/WhyChooseUs";
import './Layout.css'

export default function Layout() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }, []);

    return (
        <>
            {loading ? (
                <div className='spinner-div'>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <section id='home' className={'header-bar'}>
                        <Navbar/>
                        <NavbarSecond/>
                        <Moto/>
                    </section>
                    <section id='about'>
                        <About />
                    </section>
                    <section>
                        <Counter/>
                    </section>
                    <section id='services'>
                        <Services/>
                    </section>
                    <section id='strategy'>
                        <Strategy/>
                    </section>
                    <section id='team'>
                        <Team/>
                    </section>
                    <section id='chooseUs'>
                        <WhyChooseUs/>
                    </section>
                    <section id='callback'>
                        <Callback/>
                    </section>
                    <FooterMain/>
                    <button
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth', duration: 1 });
                        }}
                        style={{
                            position: 'fixed',
                            bottom: '27px',
                            right: '27px',
                            textAlign: 'center',
                            borderRadius: '50%',
                            background: 'transparent',
                            border: 'none'
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowUp} className={'about-icons border-0 arrow-up-icon'}/>
                    </button>

                </>
            )}
        </>
    )
}



