import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import './About.css';

export default function About() {
    const { t } = useTranslation();

    return (
      <div className={'about-block'} >
          <Container className={'mt-5'}>
              <Row>
                  <Col lg={6}>
                      <div className={'partners-header-block text-center'}>
                          <div className={'title-design'}></div>
                          <h2 className={'block-title'}>{t('translation.aboutUs')}</h2>
                          <div className="sub-title">{t('translation.weDevelop')}</div>
                          <span className="bottom-title"></span>
                      </div>
                      <p>{t('translation.ElTi')}
                      </p>
                  </Col>
                  <Col className={'partner-image-header'}></Col>
              </Row>
          </Container>
      </div>
    );
}
