import ServicesCarousel from "./ServicesCarousel";
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import './Services.css'


export default function Services() {
    const { t } = useTranslation();

    return (
       <div>
           <div className={'services-block py-5'}>
               <div className={'container partners-header-block text-center'}>
                   <div className={'title-design'}></div>
                   <h2 className={'block-title'}>{t('translation.weSuggest')}</h2>
                   <div className="sub-title">{t('translation.weServe')}</div>
                   <span className="bottom-title"></span>
               </div>
           </div>
           <Container fluid className={'p-0'}>
               <ServicesCarousel/>
           </Container>
       </div>
    );
}
