import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTelegram, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Select } from 'antd';
import arm from '../../Images/arm.jpg';
import en from '../../Images/en.jpg';
import ru from '../../Images/rus.jpg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import enTranslation from '../Translations/en/translation.json';
import armTranslation from '../Translations/arm/translation.json';
import ruTranslation from '../Translations/ru/translation.json';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init(
        {
            resources: {
                armenian: {
                    translation: armTranslation,
                },
                english: {
                    translation: enTranslation,
                },
                russian: {
                    translation: ruTranslation,
                },
            },
            lng: 'armenian',
            fallbackLng: 'armenian',
            interpolation: {
                escapeValue: false,
            },
        },
        (err, t) => {
            if (err) {
                console.error('Error loading translations:', err);
            }
        }
    )
    .then((t) => {
        // Additional code that relies on the translation function (t)
    });

function NavbarFirst() {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (value) => {
        console.log(value, 'value');
        i18n.changeLanguage(value);
    };

    const selectedLanguage = i18n.languages[0];

    return (
        <Navbar bg="transparent" className='d-lg-block d-none'>
            <Container className={'mt-3'}>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link href="#" className={'navbar-details'}><FontAwesomeIcon icon={faMap} className={'font-icons'}/> {' '}
                            {t('translation.location')}</Nav.Link>
                        <Nav.Link href="#" className={'navbar-details'}><FontAwesomeIcon icon={faMessage} className={'font-icons'}/>info@gabrielyans.com</Nav.Link>
                        <Nav.Link href="#" className={'navbar-details'}><FontAwesomeIcon icon={faPhone} className={'font-icons'}/>+374 33777756</Nav.Link>
                    </Nav>
                    <div className="d-flex contact-icons">
                        <Nav.Link href="https://www.facebook.com/gabrielyansaccounting/?locale=ru_RU" className={'navbar-details'} target="_blank"><FontAwesomeIcon icon={faFacebookF} className={'font-icons-social'}/></Nav.Link>
                        <Nav.Link href="https://t.me/+37477483777?fbclid=IwAR0PgUP_aMV3R0feQjLoozoBsnGhEZDegfJv-nxeSDB7aGxEFR22OEuDMKc" className={'navbar-details'} target="_blank"><FontAwesomeIcon icon={faTelegram} className={'font-icons-social'}/></Nav.Link>
                        <Nav.Link href="https://www.instagram.com/gabrielyans_accounting/" className={'navbar-details'} target="_blank"><FontAwesomeIcon icon={faInstagram} className={'font-icons-social'} /></Nav.Link>
                        <Nav.Link href="https://www.tiktok.com/@gabrielyansaccounting?fbclid=IwAR2_IV9744rQmUAXkm16jeA2_IJWYANMMwtNDO7rCl0IsB7SNN3s_MhsxtM" className={'navbar-details'} target="_blank"><FontAwesomeIcon icon={faTiktok} className={'font-icons-social'}/></Nav.Link>
                        <Select
                            defaultValue={selectedLanguage}
                            style={{ width: 100 }}
                            className={'select-lang'}
                            options={[
                                {
                                    value: 'armenian',
                                    label: (
                                        <img src={arm} className={'w-25'} alt="Armenian" />
                                    ),
                                },
                                {
                                    value: 'english',
                                    label: (
                                        <img src={en} className={'w-25'} alt="English" />
                                    ),
                                },
                                {
                                    value: 'russian',
                                    label: (
                                        <img src={ru} className={'w-25'} alt="Russian" />
                                    ),
                                },
                            ]}
                            onChange={handleLanguageChange}
                        />
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarFirst;